import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  // <--- Interceptor Service --->

  public getRequest(Api: string, params?: any): Observable<any> {
    return this.http.get(Api, { params: params }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  //by DP,, To handle candidates list by type
  public getRequestByType(Api: string,id:any, params?: any): Observable<any> {
    return this.http.get(`${Api}${id}`, { params: params }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public postRequest(Api: string, payload: any, params?: any): Observable<any> {
    return this.http.post(Api, payload, { params: params }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public putRequest(Api: string, payload: any, params?: any): Observable<any> {
    return this.http.put(Api, payload, { params: params }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public deleteRequest(Api: string, params?: any): Observable<any> {
    return this.http.delete(Api, { params: params }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public patchRequest(Api: string, payload: any): Observable<any> {
    //console.log(payload);
    return this.http.patch(Api, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  // <--- Interceptor Service Ends --->
}
