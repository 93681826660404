export const environment = {
  environment: "production",
  refreshApiCallTime: (24 * 60 * 60 * 1000),
  firebaseAuth: false,
  production: true,
  baseUrl: 'https://hirewalks.com/api/v1',
  baseUrl2: 'https://hirewalks.com/api/v2',
  baseUrl3: 'https://hirewalks.com/api/v3',
  server: 'https://portal.hirewalks.com/',
  ulrToDisplayImage: 'https://hirewalks.com/api/v1/details/imagedisplay/',
  socketURL: 'https://hirewalks.com/api/v1', //for socket connection
  candidateURL: 'https://hirewalks.com',
  recruiterURL: 'https://hirewalks.com/recruiters',
  feedBackUrl: 'https://interview-feedback.hirewalks.com',

  // Base URLs
  hrBasePath: 'https://api-vendor-znpc27vwgq-el.a.run.app',
  jobsBasePath: 'https://api-jobs-znpc27vwgq-el.a.run.app',
  jobsAtsBasePath: 'https://api-job-ats-znpc27vwgq-el.a.run.app',
  masterBasePath: 'https://api-master-znpc27vwgq-el.a.run.app',
  chatBasePath: 'https://api-chat-znpc27vwgq-el.a.run.app',
  offersBasePath: 'https://api-offer-check-znpc27vwgq-el.a.run.app',
  subsBasePath: 'https://api-payment-znpc27vwgq-el.a.run.app',
  vendorNotifyBasePath: 'https://api-vendor-notifications-znpc27vwgq-el.a.run.app',
  candBasePath: 'https://api-candidate-znpc27vwgq-el.a.run.app',
  profileBasePath: 'https://api-dbsearch-znpc27vwgq-el.a.run.app',
  candAuthBasePath: 'https://api-candidate-auth-znpc27vwgq-el.a.run.app',
  vendorAuthBasePath: 'https://api-vendor-auth-znpc27vwgq-el.a.run.app',
  devAtsBasePath: 'https://api-job-ats-znpc27vwgq-el.a.run.app',

  firebaseConfig: {
    apiKey: 'AIzaSyC_kSRScso7HKIAgQOlxwf8i2WMjeHuIvo',
    authDomain: 'hirewalks-16c25.firebaseapp.com',
    projectId: 'hirewalks-16c25',
    storageBucket: 'hirewalks-16c25.appspot.com',
    messagingSenderId: '14576892119',
    appId: '1:14576892119:web:71a6065c5a8055faf21892',
    measurementId: 'G-QWC5TBVDBY',
  },
};
